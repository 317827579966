#info{
    padding-top:150px;
    padding-bottom: 150px;
    background: #F9E0E3;
    padding-left: 100px;
    padding-right: 100px;
}

#info-content{
    display: flex;
    flex-direction: row;
    column-gap: 60px;
    z-index: 2;
    flex-wrap: wrap;
    justify-content: center;
    /* margin-left: 10px; */
}
#globe{
   display: flex;
   
}
#contatc-button:hover{
    cursor: pointer;
}
#globe img{
    margin-top: 40px;
    max-height: 100%;
    max-width: 100%;
    border-radius: 32px
}

#info-name{
    font-family: 'Lexend';
    font-style: normal;
    font-weight: 500;
    font-size: 48px;
    line-height: 60px;
    color: #E23125;
}
#name{
    font-family: 'Lexend';
    font-style: normal;
    font-weight: 300;
    font-size: 40px;
    line-height: 50px;
    color: #E23125;
}
#contact-button{
    text-decoration: none;
    padding:14px 30px 14px 30px ;
    background: #E23125;
    border: none;
    border-radius: 8px;
    font-family: 'Lexend';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #FFFFFF;
    /* margin-bottom: 40px; */
}

@media screen and (max-width:1459px) {
    #info-content{
        justify-content: left;
        
    }
    #info{
        padding-left:20%;
        
    }
}
@media screen and (max-width:1050px) {
    #info{
        padding-left: 10%;
        padding-right: 20px;
        
    }
}
@media screen and (max-width:600px) {
    #info-name{
        line-height: 30px;
        font-weight: 500;
        font-size: 30px;
        
    }
    #name{
        font-size: 20px;
        line-height: 0px;
        
    }
}
@media screen and (max-width:800px) {
    #info-name{
        line-height: 50px;
        font-weight: 500;
        font-size: 40px;
        
    }
    #name{
        font-size: 30px;
        line-height: 10px;
        
    }
    
    #info{
        padding-top:75px;
        padding-bottom:75px;
        padding-left: 20px;
        padding-right: 20px;
        
        
    }
}





#competence{
    padding:150px 50px 150px 50px
}

#competence-content{
    display: flex;
    width: 100%;
     flex-direction: row;
    column-gap: 60px;
    z-index: 2;
    flex-wrap: wrap;
    justify-content: center;
}
#certified{
   display: flex;
   
}
#certificate img{
    max-height: 100%;
    max-width: calc(100% - 50px);
    margin-left: 25px;
}
#competence-info{
    max-width: 720px;
}
#kompetencje{
    font-family: 'Lexend';
    font-style: normal;
    font-weight: 500;
    font-size: 48px;
    line-height: 60px;
    color: #E23125;
}
.text-competence{
    font-family: 'Lexend';
    font-style: normal;
    font-weight: 300;
    font-size: 28px;
    line-height: 35px;
    color: #E23125;
}

#name2{
    font-family: 'Lexend';
    font-style: normal;
    font-weight: 300;
    font-size: 40px;
    line-height: 50px;
    text-align: right;
    color: #E23125;

}
@media screen and (max-width:800px) {
    #competence{
        padding:75px 30px 75px 30px
    }
   #kompetencje{
    font-size: 40px;
    line-height: 50px;
    
   }
   #name2{
    font-size: 34px;
    line-height: 30px;
    

    }
    .text-competence{
        font-size: 25px;
        line-height: 25px;
        
    }
        
    
        
    
}
@media screen and (max-width:600px) {
    #competence{
        padding:75px 10px 75px 10px
    }
    #kompetencje{
        font-size: 30px;
        line-height: 30px;
    }
    #name2{
        font-size: 25px;
        line-height: 25px;
    }
    .text-competence{
        font-size: 20px;
        line-height: 20px;
    }  
}
